import { faHouse } from '@fortawesome/pro-regular-svg-icons/faHouse';
import { faHouseChimneyHeart } from '@fortawesome/pro-regular-svg-icons/faHouseChimneyHeart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';

import Card, { CardWrapper } from 'components/authentication/Card';
import { addressObjectToString } from 'components/strombestilling/components/SearchAddressField';
import {
	Header,
	Layout,
	Push,
} from 'components/strombestilling/components/StromStyles';
import {
	StromContext,
	emptyAddress,
} from 'components/strombestilling/helpers/strombestillingProvider';
import usePageNavigation, {
	stromPages,
	stromPagesBaseUrl,
	stromUrlParams,
} from 'components/strombestilling/helpers/usePageNavigation';
import useSale, {
	salgsStatuser,
} from 'components/strombestilling/helpers/useSale';
import useTracking, {
	steps,
} from 'components/strombestilling/helpers/useTracking';
import useValidate from 'components/strombestilling/helpers/useValidate';
import LoadingPage from 'components/strombestilling/pages/helperPages/loadingPage';
import { useAuth } from 'context/AuthProvider';

export default function PickPath() {
	const { isAuthenticated, isLoading: isAuthLoading, logout } = useAuth();
	const { navigatePage, getLogoutUrl } = usePageNavigation();
	const { getValidatedMeters } = useValidate();
	const { trackCheckoutStarted, trackStepCompleted } =
		useTracking();
	const { updateSaleStatus } = useSale();
	const {
		isLoading: isContextLoading,
		order,
		updateOrder,
		user,
		updateSearchResult,
		fetchAndSetUserData,
		storeBeforeLogout,
	} = useContext(StromContext);

	const [isButtonLoading, setIsButtonLoading] = useState(false);

	useEffect(() => {
		// Log out on back navigation instead of going to keycloak login, same as in addOrgnumber
		function handleBackNavigation(e) {
			if (
				window.location.pathname ===
				stromPagesBaseUrl + stromPages.loginSuccess.url + '/' ||
				window.location.pathname ===
				stromPagesBaseUrl + stromPages.loginSuccess.url
			) {
				storeBeforeLogout();
				logout({ redirect: getLogoutUrl(stromPages.login.url) });
			}
		}

		window.addEventListener('popstate', handleBackNavigation);
		return () => {
			window.removeEventListener('popstate', handleBackNavigation);
		};
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const params = new URLSearchParams(window.location?.search);
		const isRecentlyloggedIn = params.has(stromUrlParams.login);

		if (!isRecentlyloggedIn || !isAuthenticated) return;

		fetchAndSetUserData().then(() => {
			params.delete(stromUrlParams.login);

			window.history.replaceState(
				{},
				document.title,
				window.location.pathname
			);
			trackCheckoutStarted();
			updateSaleStatus(salgsStatuser.loggedIn);
		});

		// eslint-disable-next-line
	}, [isAuthenticated]);

	useEffect(() => {
		// If address isn't found during login, automatically go to search for address page to prevent infinite load
		if (order && order.isBedrift) {
			if (user && user.orgnr && !order?.bedriftData?.adresse?.gate) {
				handleOtherAddress();
			}
		} else {
			if (user && user.ssn && !user?.folkeregAdresse?.gate) {
				handleOtherAddress();
			}
		}
		// eslint-disable-next-line
	}, [user, order]);

	async function handlePrimaryAddress(isBedrift) {
		setIsButtonLoading(true);
		const meters = isBedrift ? order.bedriftData?.maalere : user?.maalere;
		const address = isBedrift
			? order.bedriftData?.adresse
			: user.folkeregAdresse;
		trackStepCompleted(steps[2]);

		if (!meters || meters?.length === 0) {
			updateSearchResult({
				maalerAdresse: address,
			});
			updateOrder({
				maalerAdresse: emptyAddress,
				maalere: [],
			});
			navigatePage(stromPages.searchMeternumber.url);
		} else {
			const validationResult = await getValidatedMeters(meters);

			if (validationResult.infoArray?.length === meters?.length) {
				navigatePage(
					stromPages.errorPage.url,
					stromUrlParams.ruleBroken
				);
				return;
			}

			if (meters?.length === 1) {
				updateSearchResult({
					maalerAdresse: emptyAddress,
					maalere: [],
				});
				updateOrder({
					maalerAdresse: address,
					maalere: meters,
					isManual: false,
				});
				trackStepCompleted(steps[3]);
				updateSaleStatus(salgsStatuser.meterFound);
				navigatePage(stromPages.addStartdate.url);
			} else {
				updateSearchResult({
					maalerAdresse: address,
					maalere: meters,
				});
				updateOrder({
					maalerAdresse: emptyAddress,
					maalere: [],
				});
				navigatePage(stromPages.pickMeter.url);
			}
		}
	}

	function handleOtherAddress() {
		setIsButtonLoading(true);
		trackStepCompleted(steps[2]);

		updateSearchResult({
			maalerAdresse: emptyAddress,
			maalere: [],
		});
		updateOrder({
			maalerAdresse: emptyAddress,
			maalere: [],
		});
		navigatePage(stromPages.searchAddress.url);
	}

	if (
		isAuthLoading ||
		isContextLoading ||
		isButtonLoading ||
		window?.location?.search === '?' + stromUrlParams.login ||
		(order?.isBedrift && !order?.bedriftData?.adresse?.gate) ||
		(!order?.isBedrift && !user?.folkeregAdresse?.gate)
	) {
		return <LoadingPage text={isAuthenticated ? 'Laster' : 'Logger inn'} />;
	}

	return (
		<Layout>
			{(order.isBedrift && (
				<>
					<Header>Hvor vil du bestille?</Header>
					<Push />
					<CardWrapper>
						<Card
							title={addressObjectToString(
								order.bedriftData?.adresse
							)}
							description={order.bedriftData?.navn}
							icon={
								<FontAwesomeIcon icon={faHouseChimneyHeart} />
							}
							onClick={() => handlePrimaryAddress(true)}
						/>

						<Card
							title="Bestill til en annen adresse"
							description={order.bedriftData?.navn}
							icon={<FontAwesomeIcon icon={faHouse} />}
							onClick={handleOtherAddress}
						/>
					</CardWrapper>
				</>
			)) || (
					<>
						<Header>Hvilken adresse vil du bestille til?</Header>
						<Push />
						<CardWrapper>
							<Card
								title={`${user.folkeregAdresse.gate} ${user.folkeregAdresse.husnummer}`}
								description={`${user.folkeregAdresse.postnummer} ${user.folkeregAdresse.poststed}`}
								style={{ height: '4.2rem' }}
								icon={
									<FontAwesomeIcon
										icon={faHouseChimneyHeart}
										style={{ height: '1.5rem' }}
									/>
								}
								onClick={handlePrimaryAddress}
							/>

							<Card
								title="Bestill til en annen adresse"
								style={{ height: '4.2rem' }}
								icon={
									<FontAwesomeIcon
										icon={faHouse}
										style={{ height: '1.5rem' }}
									/>
								}
								onClick={handleOtherAddress}
							/>
						</CardWrapper>
					</>
				)}
		</Layout>
	);
}
