import { StromContext } from 'components/strombestilling/helpers/strombestillingProvider';
import { trackBackEnd } from 'context/AnalyticsProvider';
import { useAuth } from 'context/AuthProvider';
import { useContext } from 'react';

export const steps = {
	1: '1: Logg inn',
	11: '1a: Salgskode brukt',
	2: '2: Velg løype',
	21: '2a: Sett orgnummer',
	3: '3: Finn måler',
	31: '3a: Finn adresse',
	32: '3b: Finn målernummer',
	33: '3c: Flere målernumre',
	4: '4: Sett oppstartsdato',
	5: '5: Bekreft kontaktinfo',
	6: '6: Send bestilling',
	7: '7: Takk for bestilling',
	8: '8: Feil',
	9: '9: Bestilling feilet',
};

export const feedbackTypes = { negative: 'Negative', positive: 'Positive' };

export default function useTracking() {
	const { trackingEvents, isTrackingPending, order } =
		useContext(StromContext);
	const { method: authMethod } = useAuth();

	function addTrackingToQueue(event, properties, method) {
		const job = () => trackBackEndStrom(event, properties, method);

		if (trackingEvents?.current) {
			new Promise((resolve, reject) => {
				trackingEvents.current.push({
					job,
					resolve,
					reject,
				});
			});
			nextJob();
		} else {
			if (isTrackingPending?.current) {
				isTrackingPending.current = true;
				job.then(() => {
					isTrackingPending.current = false;
				}).catch(error => {
					console.error(error);
					isTrackingPending.current = false;
				});
			}
		}
	}

	function nextJob() {
		if (isTrackingPending?.current) return;
		const next = trackingEvents?.current.shift();

		if (next) {
			isTrackingPending.current = true;
			next.job()
				.then(value => {
					next.resolve(value);
					isTrackingPending.current = false;
					nextJob();
				})
				.catch(error => {
					console.error(error);
					next.reject(error);
					isTrackingPending.current = false;
					nextJob();
				});
		}
	}

	async function trackBackEndStrom(event, properties, method) {
		return trackBackEnd(
			event,
			{
				...properties,
				variation: 'Ny strømbestilling',
				sale_code: order?.salgskode || undefined,
				sale_person_id: order?.salgsData?.selgerkode || undefined,
				sale_store: order?.salgsData?.butikk || undefined,
			},
			method
		);
	}

	function trackCheckoutStarted() {
		addTrackingToQueue('Checkout Started', {
			name: order.tariffData?.navn,
			product_id: order.tariffId,
			value: 1000,
			currency: 'NOK',
			payment_method: authMethod || undefined,
			affiliation: order.vervekode ? 'Verving' : undefined,
		});
	}

	function trackStep(eventName, step, label) {
		addTrackingToQueue(eventName, {
			name: order.tariffData?.navn,
			product_id: order.tariffId,
			value: 1000,
			currency: 'NOK',
			payment_method: authMethod || undefined,
			affiliation: order.vervekode ? 'Verving' : undefined,
			step: step,
			label: label,
		});
	}

	function trackStepViewed(step, label) {
		trackStep('Checkout Step Viewed', step, label);
	}

	function trackStepCompleted(step, label) {
		trackStep('Checkout Step Completed', step, label);
	}

	function trackSaleStarted(productId, code) {
		trackBackEndStrom('Sale Process Started', {
			product_id: productId,
			salescode: code,
		});
	}

	function trackFeedbackGiven(type) {
		addTrackingToQueue('Feedback Given', {
			type: type,
		});
	}

	return {
		trackCheckoutStarted,
		trackStepViewed,
		trackStepCompleted,
		trackFeedbackGiven,
		trackSaleStarted,
	};
}
