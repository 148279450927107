import Link, { AnchorLink } from 'components/Link';
import {
	Button,
	ContentWrapper,
	Header,
	Layout,
} from 'components/strombestilling/components/StromStyles';
import { StromContext } from 'components/strombestilling/helpers/strombestillingProvider';
import usePageNavigation, {
	stromPages,
	stromUrlParams,
} from 'components/strombestilling/helpers/usePageNavigation';
import useSale, {
	salgsStatuser,
} from 'components/strombestilling/helpers/useSale';
import { contactSpan } from 'components/strombestilling/helpers/useValidate';
import { StikkkontaktIcon } from 'components/strombestilling/pages/helperPages/errorPage';
import { useAuth } from 'context/AuthProvider';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

const NarrowLayout = styled(Layout)`
	max-width: 37.5rem;
	margin: 0 auto;
`;

const CenteredHeader = styled(Header)`
	text-align: ${p => (p.$centered ? 'center' : 'left')};
`;

// Mirrors errorPage.js
export default function OrderFailed({ location }) {
	const { isAuthenticated, isLoading, logout } = useAuth();
	const { storeBeforeLogout, storedElektroOrder, clearElektroOrder } =
		useContext(StromContext);
	const { updateSaleStatus } = useSale();
	const { navigatePage } = usePageNavigation();

	const [header, setHeader] = useState('');
	const [body, setBody] = useState(null);
	const [displayIcon, setDisplayIcon] = useState(false);

	const params = new URLSearchParams(location?.search);
	const errorType = params?.entries()?.next()?.value?.[0];

	useEffect(() => {
		if (isAuthenticated && !isLoading) {
			storeBeforeLogout();

			// Don't log out if electro order, user might want to return to order process
			if (storedElektroOrder) {
				clearElektroOrder();
			} else {
				logout();
			}
		}
		// eslint-disable-next-line
	}, [
		isAuthenticated,
		isLoading,
		logout,
		storeBeforeLogout,
		storedElektroOrder,
	]);

	useEffect(() => {
		if (!errorType) return;
		if (errorType === stromUrlParams.techError) {
			updateSaleStatus(salgsStatuser.failedTech);
		} else {
			updateSaleStatus(salgsStatuser.failed);
		}
		// eslint-disable-next-line
	}, [errorType]);

	useEffect(() => {
		if (!header || !body) {
			switch (errorType) {
				case stromUrlParams.tariffError:
					setHeader('Strømavtalen finnes ikke');
					setBody(
						<span>
							Prøv å gå tilbake til{' '}
							<AnchorLink href="/strom">
								oversikten over strømavtalene våre
							</AnchorLink>{' '}
							og velg avtale på nytt.
							<br />
							<br />
							Om problemet oppstår igjen, vennligst ta kontakt med{' '}
							{contactSpan}.
						</span>
					);
					setDisplayIcon(true);
					break;
				case stromUrlParams.vervekodeError:
					setHeader('Vervekoden mangler eller er ugyldig');
					setBody(
						<span>
							Vi klarer ikke å verifisere vervekoden din. Er du
							sikker på at lenken er riktig? Prøv å trykke på
							lenken igjen, og hvis du fortsatt får feil, ta
							kontakt med {contactSpan}.
						</span>
					);
					setDisplayIcon(true);
					break;
				case stromUrlParams.salgskodeError:
					setHeader('Salgskoden mangler eller er ugyldig');
					setBody(
						<span>
							Vi klarer ikke å verifisere salgskoden din. Prøv å
							trykke på lenken igjen, eller be selgeren generere
							en ny kode. Hvis du fortsatt får feil, ta kontakt
							med {contactSpan}.
						</span>
					);
					setDisplayIcon(true);
					break;
				case stromUrlParams.loginSsnError:
					setHeader('Beklager, noe gikk galt med identifiseringen');
					setBody(
						<span>
							Vi fikk ikke tak i informasjonen vi trenger om deg,
							og kan ikke gå videre med bestillingen. Vi vil
							anbefale deg å gå tilbake til innloggingssiden og
							prøve på nytt.
							<br />
							<br />
							Om problemet oppstår igjen, ta kontakt med{' '}
							{contactSpan}.
							<br />
							<br />
							<Button
								onClick={() => {
									navigatePage(stromPages.login.url);
								}}>
								Tilbake til innloggingssiden
							</Button>
						</span>
					);
					break;
				case stromUrlParams.loginFetchError:
					setHeader('Beklager, noe gikk galt med identifiseringen');
					setBody(
						<span>
							Vi fikk ikke tak i informasjonen vi trenger fra
							folkeregistrert, og kan ikke gå videre med
							bestillingen. Dette kan være en midlertidig feil,
							prøv gjerne igjen senere.
							<br />
							<br />
							Om problemet oppstår igjen, ta kontakt med{' '}
							{contactSpan}.
							<br />
							<br />
							<Button
								onClick={() => {
									navigatePage(stromPages.login.url);
								}}>
								Tilbake til innloggingssiden
							</Button>
						</span>
					);
					break;
				case stromUrlParams.creditBlockedError:
					setHeader('Bestillingen ble ikke gjennomført');
					setBody(
						<span>
							Vi fikk ikke gjennomført kredittsjekk fordi det
							finnes en kredittsperre på deg. Kredittsperren
							opphever du enklest ved å logge inn med BankID på{' '}
							<AnchorLink
								href="https://soliditet.no/privatpersoner/auth"
								target="_blank"
								rel="noreferrer">
								Dun & Bradstreet sine nettsider
							</AnchorLink>
							. Når du har opphevet sperren kan du normalt prøve å
							registrere bestillingen på nytt etter en halvtime,
							men det kan ta opptil et døgn.
						</span>
					);
					setDisplayIcon(true);
					break;
				case stromUrlParams.creditFailedError:
					setHeader('Bestillingen ble ikke gjennomført');
					setBody(
						<span>
							Kredittvurderingen ble dessverre ikke godkjent.
							<br />
							<br />
							Hvis du mener dette er en feil må du gjerne ta
							kontakt med {contactSpan}, så hjelper vi deg gjerne.
						</span>
					);
					break;
				case stromUrlParams.techError:
					setHeader('Teknisk feil');
					setBody(
						<span>
							Vi beklager, det har oppstått en teknisk feil og vi
							fikk ikke gjennomført bestillingen.
							<br />
							<br />
							Prøv gjerne igjen senere, eller ta kontakt med{' '}
							{contactSpan}, så hjelper vi deg gjerne.
						</span>
					);
					setDisplayIcon(true);
					break;
				case stromUrlParams.duplicateOrderError:
					setHeader('Duplikat ordre');
					setBody(
						<span>
							Denne avtalen er allerede bestilt til denne måleren,
							og kan ikke bestilles igjen. Prøv å bestille til en
							annen måler.
							<br />
							<br />
							Hvis du mener det er feil, ta kontakt med{' '}
							{contactSpan}, så hjelper vi deg gjerne.
						</span>
					);
					break;

				case stromUrlParams.manualElektroError:
					setHeader('Bestillingen kan ikke gjennomføres');
					setBody(
						<span>
							Bestilling av{' '}
							<b>{storedElektroOrder?.product?.internalTitle}</b>{' '}
							på avbetaling med{' '}
							{storedElektroOrder?.power?.product} kan ikke
							gjennomføres uten målernummer eller målepunkt-ID.
						</span>
					);
					break;

				default:
					setHeader('Noe gikk galt');
					setBody(
						<span>
							Vi beklager, en uventet feil oppstod, og vi kan ikke
							fortsette bestillingen.
							<br />
							<br />
							Prøv gjerne igjen senere, eller ta kontakt med{' '}
							{contactSpan}, så hjelper vi deg gjerne.
						</span>
					);
					setDisplayIcon(true);
					break;
			}
		}
		// eslint-disable-next-line
	}, [params]);

	return (
		<NarrowLayout>
			<CenteredHeader $centered={displayIcon}>{header}</CenteredHeader>
			<ContentWrapper>
				{displayIcon && (
					<StikkkontaktIcon
						role="img"
						aria-label="Illustrasjon av en stikkontakt som blir plugget ut"
					/>
				)}
				{body}
				{!!storedElektroOrder && (
					<ElektroOrderCallToAction
						slug={storedElektroOrder?.product?.slug}
					/>
				)}
			</ContentWrapper>
		</NarrowLayout>
	);
}

/**
 * Call to action for Elektro order, if the order failed due to missing meter number or failed credit check
 * @param {string} slug - The slug of the product (Elektro)
 */
function ElektroOrderCallToAction({ slug }) {
	if (!slug) return null;
	return (
		<div>
			Du kan{' '}
			<Link to={`/produkter/bestill?produkt=${slug}`}>
				gå tilbake og bestille produktet med faktura som betalingsmetode
			</Link>
			.
		</div>
	);
}
