import InputField from 'components/strombestilling/components/InputField';
import searchAddress from 'libs/GeoNorge';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const SearchFieldWrapper = styled.div`
	max-width: 100%;
	position: relative;
`;

const AddressList = styled.ul`
	position: absolute;
	z-index: 1;
	width: 100%;
	margin-top: -1rem;
	background-color: white;
	padding: 1.3rem 0 0;
	box-sizing: border-box;
	overflow: auto;
	border-radius: 0.5rem;
	border: 1px solid ${p => p.theme.colors.grey300};
	list-style-type: none;
`;

const AddressItem = styled.li`
	cursor: pointer;
	transition: all 0.2s;
	padding: 8px 16px;
	border: solid 2px transparent;
	font-size: 0.875rem;
	transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 10ms;

	&[aria-selected='true'],
	&:hover {
		background-color: ${p => p.theme.colors.blue100};
	}
	& :active {
		background-color: ${p => p.theme.colors.blue600};
		color: white;
	}
`;

export function addressObjectToString(adresse) {
	if (!adresse) {
		return '';
	}
	return `${adresse.gate || ''}${
		adresse.husnummer ? ' ' + adresse.husnummer : ''
	}, ${adresse.postnummer || ''} ${adresse.poststed || ''}`;
}

export default function SearchAddressField({
	inputRef,
	pickedAddress,
	setPickedAddress,
	customTitle,
	error,
	setError,
	searchIsActive,
	setSearchIsActive,
	...rest
}) {
	const [searchAddresses, setSearchAddresses] = useState([]);
	const [a11yMessage, setA11yMessage] = useState();
	document.onkeydown = checkKey;

	function changeSelectedOption(
		inputElement,
		resultsElement,
		newSelectedIndex,
		selectedIndex
	) {
		inputElement.setAttribute(
			'aria-activedescendant',
			`address-${newSelectedIndex || 0}`
		);
		resultsElement?.childNodes[newSelectedIndex || 0]?.setAttribute(
			'aria-selected',
			'true'
		);
		resultsElement.childNodes[newSelectedIndex || 0].className +=
			' selected';
		resultsElement?.childNodes[selectedIndex]?.setAttribute(
			'aria-selected',
			'false'
		);
		if (!isNaN(selectedIndex)) {
			resultsElement.childNodes[selectedIndex].className =
				resultsElement?.childNodes[selectedIndex].className.split(
					' selected'
				)[0];
		}
	}
	function checkKey(e) {
		e = e || window.event;
		let selectedIndex;
		const resultsElement = document.getElementById('results');
		const inputElement = inputRef?.current;

		if (
			!resultsElement ||
			resultsElement?.childNodes?.length === 0 ||
			!inputElement?.value ||
			searchAddresses?.length === 0
		) {
			inputElement?.setAttribute('aria-activedescendant', '');
			return;
		}

		resultsElement?.childNodes.forEach((result, i) => {
			if (
				result.ariaSelected === 'true' ||
				result.className.indexOf('selected') !== -1
			) {
				selectedIndex = i;
			}
			return result;
		});

		if (e.keyCode === '38' || e.key === 'ArrowUp') {
			e.preventDefault();
			let newSelectedIndex =
				selectedIndex === 0
					? resultsElement?.childNodes?.length - 1
					: selectedIndex - 1;
			changeSelectedOption(
				inputElement,
				resultsElement,
				newSelectedIndex,
				selectedIndex
			);
		} else if (e.keyCode === '40' || e.key === 'ArrowDown') {
			e.preventDefault();
			const newSelectedIndex =
				selectedIndex === resultsElement?.childNodes?.length - 1
					? 0
					: selectedIndex + 1;
			changeSelectedOption(
				inputElement,
				resultsElement,
				newSelectedIndex,
				selectedIndex
			);
		} else if (e.keyCode === '13' || e.key === 'Enter') {
			resultsElement?.childNodes[selectedIndex]?.click();
			inputRef.current.blur();
		} else if (e.keyCode === '27' || e.key === 'Escape') {
			inputRef.current.blur();
		}
		if (resultsElement?.childNodes?.length === 1) {
			resultsElement?.childNodes[0]?.setAttribute(
				'aria-selected',
				'true'
			);
			resultsElement.childNodes[0].className += ' selected';
			inputElement?.setAttribute('aria-activedescendant', 'address-0');
		}
	}

	useEffect(() => {
		if (pickedAddress?.resultat) {
			inputRef.current.value = pickedAddress.resultat;
		} else if (pickedAddress?.gate) {
			inputRef.current.value = addressObjectToString(pickedAddress);
		}
	}, [pickedAddress, inputRef]);

	useEffect(() => {
		if (searchAddresses?.length > 0) {
			setA11yMessage(
				`Listen viser ${searchAddresses?.length} treff på adresse, bruk opp eller ned pil for navigasjon. Trykk eller bruk Enter tasten for å velge.`
			);
		} else if (
			inputRef?.current?.value !== '' &&
			pickedAddress?.resultat?.length === 0
		) {
			setA11yMessage(
				`Fant ingen adresser som tilsvarer søket ditt, ${inputRef?.current.value}. Skriv adressen på nytt eller gå videre.`
			);
		}
	}, [searchAddresses, pickedAddress, setA11yMessage, inputRef]);

	return (
		<SearchFieldWrapper>
			<InputField
				name="adresse-search-input-field"
				inputRef={inputRef}
				title={customTitle || 'Adresse'}
				error={error}
				placeholder="Gateveien 32, 1234 Poststedet"
				autoComplete="off"
				role="combobox"
				aria-expanded={searchAddresses?.length > 0 && searchIsActive}
				aria-controls="results"
				aria-haspopup="true"
				aria-activedescendant=""
				onBlur={() => {
					setSearchIsActive(false);
				}}
				onFocus={() => {
					if (searchAddresses?.length === 1) {
						inputRef?.current?.setAttribute(
							'aria-activedescendant',
							'address-0'
						);
					}
					setError?.('');
					setSearchIsActive(true);
				}}
				onChange={e => {
					setSearchIsActive(true);
					setError?.('');
					setPickedAddress(null);
					const value = e.target.value.trim();
					if (value) {
						searchAddress(
							value,
							result => {
								setSearchAddresses(result || []);
							},
							true
						);
					} else {
						setSearchAddresses([]);
					}
				}}
				{...rest}
			/>
			{searchAddresses?.length > 0 && searchIsActive && (
				<AddressList role="listbox" id="results" aria-label="adresser">
					{searchAddresses.map((address, index) => (
						<AddressItem
							role="option"
							key={index}
							aria-selected={searchAddresses?.length === 1}
							className={
								searchAddresses?.length === 1 && ' selected'
							}
							id={`address-${index}`}
							onClick={e => {
								setPickedAddress(address);
								setSearchAddresses([]);
								setA11yMessage(
									`Valgte adresse ${address.resultat}.`
								);
							}}
							onMouseDown={e => {
								setPickedAddress(address);
								setSearchAddresses([]);
								setA11yMessage(
									`Valgte adresse ${address.resultat}.`
								);
							}}
							onTouchStart={e => {
								setPickedAddress(address);
								setSearchAddresses([]);
								setA11yMessage(
									`Valgte adresse ${address.resultat}.`
								);
							}}>
							{address.resultat}
						</AddressItem>
					))}
				</AddressList>
			)}
			{a11yMessage?.length > 0 && (
				<div
					aria-hidden={!a11yMessage?.length > 0}
					aria-live="assertive"
					className="sr-only">
					{a11yMessage}
				</div>
			)}
		</SearchFieldWrapper>
	);
}
