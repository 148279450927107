import InlineModal, {
	EditFormVariants,
	InfoBoxVariants,
} from 'components/strombestilling/components/InlineModal';
import InputField from 'components/strombestilling/components/InputField';
import PhoneInputField from 'components/strombestilling/components/PhoneInputField';
import SearchAddressField, {
	addressObjectToString,
} from 'components/strombestilling/components/SearchAddressField';
import { StromFormError } from 'components/strombestilling/components/StromFormMsgs';
import {
	Button,
	ContentWrapper,
	Form,
	GreenButton,
	Header,
	Label,
	Layout,
	LinkButton,
	Push,
	SubHeader,
} from 'components/strombestilling/components/StromStyles';
import StyledCheckbox from 'components/strombestilling/components/StyledCheckbox';
import { StromContext } from 'components/strombestilling/helpers/strombestillingProvider';
import usePageNavigation, {
	stromPages,
} from 'components/strombestilling/helpers/usePageNavigation';
import useTracking, {
	steps,
} from 'components/strombestilling/helpers/useTracking';
import { authMethods, useAuth } from 'context/AuthProvider';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useContext, useRef, useState } from 'react';
import styled from 'styled-components';

const AddressWrapper = styled.div`
	font-size: 0.875rem;
	line-height: 1rem;
	display: flex;
	align-items: center;
`;

export default function AddContactinfo() {
	const { navigatePage } = usePageNavigation();
	const { user, updateUser, order, updateOrder } = useContext(StromContext);
	const { method: authMethod } = useAuth();
	const { trackStepCompleted } = useTracking();

	const [pickedAddress, setPickedAddress] = useState(null); // Address clicked on
	const [addressInputError, setAddressInputError] = useState(''); // Form error
	const [pageError, setPageError] = useState(''); // Page error
	const addressInputRef = useRef(null); // Input ref

	const [isAddressModalClicked, setIsAddressModalClicked] = useState(false); // Address input box open, based on click on modal
	const [searchIsActive, setSearchIsActive] = useState(false); // Address input field active
	const [phoneError, setPhoneError] = useState();
	const [viewAddress, setViewAddress] = useState(getViewAddress()); // Address viewed on page, the one that is added to order on onSubmit

	function getViewAddress() {
		if (order.fakturaAdresse?.gate) {
			return order.fakturaAdresse;
		} else if (order.isBedrift && order.bedriftData?.adresse?.gate) {
			return order.bedriftData?.adresse;
		} else {
			return user.folkeregAdresse;
		}
	}

	function isValidAddress(address) {
		if (
			!address ||
			!address.gate ||
			!address.postnummer ||
			!address.poststed
		) {
			return false;
		} else {
			return true;
		}
	}

	function isPhoneInputDisplayed() {
		return !user.mobil || authMethod === authMethods.bankId;
	}

	function isAddressModalOpen() {
		return !isValidAddress(viewAddress) || isAddressModalClicked;
	}

	function closeAddressModal() {
		setIsAddressModalClicked(false);
		setPickedAddress(null);
		setAddressInputError('');
		setPageError('');
	}

	function updateAddress() {
		if (pickedAddress === null) {
			setAddressInputError('Søk etter adresse og velg i nedtrekksmenyen');
			return;
		} else {
			setViewAddress(pickedAddress);
		}
		closeAddressModal();
	}

	return (
		<Layout>
			<Header>Kontaktinformasjon</Header>
			<ContentWrapper>
				<Form
					onSubmit={e => {
						e.preventDefault();
						setPhoneError('');

						if (isAddressModalOpen()) {
							setPageError(
								'Boksen for å endre fakturaadresse er åpen. Lukk den, eller lagre adressen for å fortsette.'
							);
							return;
						}

						// Update user
						const epost = e.target.epost?.value?.trim();
						const mobil = e.target.telefonnummer?.value
							?.trim()
							?.replace(/\D/g, '');
						const landskode =
							e.target['telefonnummer-cc']?.value.trim();

						if (isPhoneInputDisplayed()) {
							if (mobil?.length < 8) {
								setPhoneError('Telefonnummer er for kort');
								return;
							} else if (!landskode?.length) {
								setPhoneError('Landskode mangler');
								return;
							} else if (landskode?.length > 3) {
								setPhoneError(
									'Oppgitt landskode er feil. 47 er landskode for Norge'
								);
								return;
							}
						}

						updateUser({
							epost: epost,
							mobil: mobil || user.mobil,
							landskode: landskode || user.landskode,
						});

						// Update order
						const samtykkeMarkedsfoering =
							e.target.samtykkeMarkedsfoering?.checked;

						updateOrder({
							samtykkeMarkedsfoering: samtykkeMarkedsfoering,
							fakturaAdresse: viewAddress,
						});

						trackStepCompleted(steps[5]);
						navigatePage(stromPages.confirmOrder.url);
					}}>
					{isPhoneInputDisplayed() && (
						<PhoneInputField
							name="telefonnummer"
							required={true}
							title="Telefonnummer"
							defaultValue={user.mobil}
							defaultValueCc={user.landskode}
							onChange={() => setPhoneError('')}
							error={phoneError}
						/>
					)}

					<InputField
						name="epost"
						type="email"
						required={true}
						title="E-postadresse"
						defaultValue={user.epost}
					/>

					<AnimatePresence initial={false} mode={'wait'}>
						{(!isAddressModalOpen() && (
							<motion.div
								variants={InfoBoxVariants}
								key="info-card"
								initial="hidden"
								animate="visible"
								exit="hidden"
								aria-hidden={isAddressModalOpen()}
								style={{
									transformOrigin: 'top left',
								}}>
								<Label>Fakturaadresse</Label>
								<AddressWrapper>
									{addressObjectToString(viewAddress)}
								</AddressWrapper>

								<LinkButton
									type="button"
									style={{ justifyContent: 'flex-start' }}
									onClick={() => {
										setIsAddressModalClicked(true);
									}}
									aria-controls="inline-modal-id"
									aria-haspopup="dialog">
									Endre fakturaadresse
								</LinkButton>
							</motion.div>
						)) || (
								<motion.div
									variants={EditFormVariants}
									key="edit-form"
									initial="hidden"
									animate="visible"
									aria-hidden={!isAddressModalOpen()}
									exit="hidden"
									style={{ transformOrigin: 'top left' }}>
									<InlineModal
										title={'Ny fakturaadresse'}
										onClose={() => closeAddressModal()}
										hideClose={!isValidAddress(viewAddress)}
										id="inline-modal-id"
										aria-expanded={isAddressModalOpen()}>
										<SearchAddressField
											inputRef={addressInputRef}
											pickedAddress={pickedAddress}
											setPickedAddress={setPickedAddress}
											required={true}
											error={addressInputError}
											setError={setAddressInputError}
											searchIsActive={searchIsActive}
											setSearchIsActive={setSearchIsActive}
										/>
										<GreenButton
											type="button"
											onClick={e => {
												e.preventDefault();
												setAddressInputError('');
												updateAddress();
											}}>
											Lagre ny fakturaadresse
										</GreenButton>
									</InlineModal>
								</motion.div>
							)}
					</AnimatePresence>

					<Push />

					<SubHeader>Samtykke</SubHeader>
					<StyledCheckbox
						defaultValue={order.samtykkeMarkedsfoering}
						label="Ja, jeg vil motta tilbud, informasjon og tips fra NTE elektronisk"
						name="samtykkeMarkedsfoering"
					/>

					<Button
						type="submit"
						onClick={() => {
							if (isAddressModalOpen()) {
								setPageError(
									'Boksen for å endre fakturaadresse er åpen. Lukk den, eller lagre adressen for å fortsette.'
								);
							}
						}}>
						Gå til siste steg
					</Button>
					{pageError?.length > 0 && (
						<StromFormError
							error={pageError}
							key="addContactInfoPageError"
						/>
					)}
				</Form>
			</ContentWrapper>
		</Layout>
	);
}
