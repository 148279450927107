import DatePicker from 'components/forms/DatePicker';
import {
	StromFormError,
	StromFormRequired,
} from 'components/strombestilling/components/StromFormMsgs';
import {
	Button,
	Form,
	Header,
	Layout,
	Paragraph,
} from 'components/strombestilling/components/StromStyles';
import StyledCheckbox from 'components/strombestilling/components/StyledCheckbox';
import { StromContext } from 'components/strombestilling/helpers/strombestillingProvider';
import usePageNavigation, {
	stromPages,
} from 'components/strombestilling/helpers/usePageNavigation';
import useTracking, {
	steps,
} from 'components/strombestilling/helpers/useTracking';
import { BlueLoadingDots } from 'components/strombestilling/pages/helperPages/loadingPage';
import dayjs from 'dayjs';
import 'dayjs/locale/nb';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import { addBusinessDays } from 'libs/content';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

/* Init dayjs plugin */
dayjs.locale('nb');
dayjs.extend(isBetween);
dayjs.extend(customParseFormat);

const CenteredLoadingWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin: 2rem;
`;

export const ANGRERETT_FRIST_DAGER = 14;

export function isDateBeforeAngrerett(date) {
	const limit = dayjs().add(ANGRERETT_FRIST_DAGER + 1, 'day');
	return dayjs(date).isBefore(limit, 'day');
}

export default function AddStartdate() {
	const { navigatePage } = usePageNavigation();
	const { order, updateOrder } = useContext(StromContext);
	const { trackStepCompleted } = useTracking();

	const [selectedDate, _setSelectedDate] = useState(null);
	function setSelectedDate(value) {
		if (!value) return;

		if (isDateBeforeAngrerett(value) && order.tariffData?.angrerett) {
			setIsEarly(true);
		} else {
			setIsEarly(false);
		}

		_setSelectedDate(value.format('D. MMMM YYYY'));
	}

	const [isEarly, setIsEarly] = useState(false);
	const [formError, setFormError] = useState('');

	useEffect(() => {
		if (order.oppstartsdato) {
			setSelectedDate(dayjs(order.oppstartsdato, 'YYYY-MM-DD'));
		} else if (typeof order.tariffData?.angrerett === 'boolean') {
			if (order.tariffData?.angrerett) {
				setSelectedDate(dayjs().add(ANGRERETT_FRIST_DAGER + 1, 'day'));
			} else {
				setSelectedDate(addBusinessDays(dayjs(), 3));
			}
		}
		// eslint-disable-next-line
	}, [order.oppstartsdato, order.tariffData?.angrerett]);

	return (
		<Layout>
			<Header>Når ønsker du oppstart?</Header>
			<Paragraph>
				Gi oss beskjed om når du ønsker å starte den nye strømavtalen
				din, så skal vi gjøre vårt beste for å imøtekomme ønsket ditt.
			</Paragraph>
			<Form
				onSubmit={e => {
					e.preventDefault();
					setFormError('');

					if (!selectedDate) return;

					const samtykkeTidligStart =
						e.target.samtykkeTidligStart?.checked;
					updateOrder({
						oppstartsdato: dayjs(
							selectedDate,
							'D. MMMM YYYY'
						).format('YYYY-MM-DD'),
						samtykkeTidligStart: samtykkeTidligStart || false,
					});
					trackStepCompleted(steps[4]);

					navigatePage(stromPages.addContactinfo.url);
				}}>
				<div style={{ marginTop: '20px' }}>
					{(selectedDate && (
						<DatePicker
							name="oppstartsdato"
							id="oppstartsdato"
							label="Velg ønsket oppstartsdato"
							value={
								selectedDate ||
								dayjs().add(1, 'day').format('D. MMMM YYYY')
							}
							maxDate={dayjs().add(6, 'month').toDate()}
							minDate={dayjs().add(2, 'day').toDate()}
							format="d. MMMM yyyy"
							onChange={date => {
								if (!date) return;

								setFormError('');
								setSelectedDate(dayjs(date, 'D. MMMM YYYY'));
							}}
							required
						/>
					)) || (
						<CenteredLoadingWrapper>
							<BlueLoadingDots aria-label="Laster" />
						</CenteredLoadingWrapper>
					)}
				</div>

				{isEarly && (
					<>
						<StyledCheckbox
							required={true}
							name="samtykkeTidligStart"
							label="Jeg ønsker oppstart før angrefristen og forstår at jeg kan bli fakturert for kostnadene fra oppstart, selv om jeg benytter meg av angreretten."
							defaultValue={order.samtykkeTidligStart}
							onChange={() => setFormError('')}
						/>
						{formError && <StromFormError error={formError} />}
					</>
				)}

				<Button
					type="submit"
					onClick={() => {
						if (
							order.tariffData?.angrerett &&
							isDateBeforeAngrerett(selectedDate)
						) {
							setFormError(
								'Du må huke av at du forstår betingelsene før du går videre.'
							);
						}
					}}>
					Registrer ønsket oppstartsdato
				</Button>
			</Form>
			{isEarly && <StromFormRequired />}
		</Layout>
	);
}
