import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import TopBanner from 'parts/top-banner/TopBanner';
import ProductColumns from 'parts/product-columns/ProductColumns';
import useReferral from 'components/strombestilling/helpers/useReferral';
import useProductInfo from 'components/strombestilling/helpers/useProductInfo';

export default function ReferralHero({
	title = 'En person har invitert deg til NTE',
	text = 'Vil du også bli strømkunde hos NTE? Bestill strømavtalen Spotpris på denne siden, så får dere begge 500 kroner i rabatt på strømregningen. Vinn, vinn!',
	location = {},
}) {
	const { product: productData } = useStaticQuery(powerProductQuery);
	const { productInfo: product } = useProductInfo(productData);
	const { loading, referral, replaceName } = useReferral(location);

	return (
		<>
			<section>
				<TopBanner
					className={`power-referral-hero${
						(!referral?.kode && !loading && ' error') || ''
					}`}
					title={
						referral?.kode && title && !loading
							? replaceName(title, referral)
							: 'Beklager, her har noe gått galt'
					}
					subText={
						referral?.kode && text
							? text
							: 'Vennligst prøv igjen senere.'
					}
					bgColor="NTE Blå 100"
					showLoading={loading}
					pagesettings={{ transparentheader: 'true' }}
					settings={['Neste komponent skal flyte over']}
					{...(!referral?.kode &&
						!loading && {
							buttons: [
								{
									text: 'Gå til strømbestilling',
									href: '/strom',
								},
							],
						})}
				/>
			</section>
			{!loading && (
				<section>
					<ProductColumns
						location={location}
						perrow={1}
						settings={['Skjul tittel og intro']}
						spacing={{ bottom: 'none' }}
						products={[
							{
								...product,
								title: 'Prisinformasjon',
								slug:
									(referral?.kode &&
										`${product?.slug}?vervekode=${referral?.kode}`) ||
									product?.slug,
								readMore: true,
							},
						]}
						customButton={{
							text: `Bestill ${product?.name}`,
							slug: `?produkt=${product?.tariff}&vervekode=${referral?.kode}`,
						}}
					/>
				</section>
			)}
		</>
	);
}

/**
 * Get information about the power product to show in the referral hero
 */
const powerProductQuery = graphql`
	query powerProduct {
		product: contentfulInnholdStromprodukt(
			tariff: { eq: "standardprodukt-inn" }
		) {
			__typename
			id
			contentful_id
			internal {
				type
			}
			slug
			tariff
			name: navn
			productsegment: segment
		}
	}
`;
