import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons/faCircleCheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchAddressField from 'components/strombestilling/components/SearchAddressField';
import {
	Button,
	Form,
	Header,
	Layout,
	Paragraph,
	RuleErrorWrapper,
	StyledListOfRuleErrors,
} from 'components/strombestilling/components/StromStyles';
import { StromContext } from 'components/strombestilling/helpers/strombestillingProvider';
import useBackend from 'components/strombestilling/helpers/useBackend';
import usePageNavigation, {
	stromPages,
} from 'components/strombestilling/helpers/usePageNavigation';
import useSale, {
	salgsStatuser,
} from 'components/strombestilling/helpers/useSale';
import useTracking, {
	steps,
} from 'components/strombestilling/helpers/useTracking';
import useValidate from 'components/strombestilling/helpers/useValidate';
import React, { useContext, useRef, useState } from 'react';

export default function SearchAddress() {
	const { updateOrder, searchResult, updateSearchResult } =
		useContext(StromContext);
	const { navigatePage } = usePageNavigation();
	const { isLoading, getMeterInfoFromAddress } = useBackend();
	const { getValidatedMeters, isValidating, orderErrors } = useValidate();
	const { trackStepCompleted } = useTracking();
	const { updateSaleStatus } = useSale();

	const addressInputRef = useRef(null);

	const [pickedAddress, setPickedAddress] = useState(null);
	const [addressSearchDone, setAddressSearchDone] = useState(false);
	const [searchIsActive, setSearchIsActive] = useState(false);

	const [formError, setFormError] = useState('');

	return (
		<Layout>
			<Header>Hvilken adresse vil du bestille til?</Header>
			<Paragraph>
				Skriv adressen din i feltet under, så gjør vi et søk for å se om
				vi finner den i våre systemer.
			</Paragraph>
			<Form
				name="search-address-form"
				onSubmit={async e => {
					e.preventDefault();
					setFormError('');

					if (pickedAddress === null) {
						setFormError(
							'Søk etter adresse og velg i nedtrekksmenyen'
						);
						return;
					}
					if (searchIsActive) {
						return;
					}

					const meters = await getMeterInfoFromAddress(
						pickedAddress.gate,
						pickedAddress.husnummer,
						pickedAddress.postnummer
					);
					setAddressSearchDone(true);

					if (!meters || meters?.error || meters?.length === 0) {
						updateSearchResult({
							maalerAdresse: pickedAddress,
						});
						navigatePage(stromPages.searchMeternumber.url);
					} else {
						const validationResult = await getValidatedMeters(
							meters
						);
						if (validationResult.showError) {
							setFormError(
								<RuleErrorWrapper>
									{meters?.length === 1
										? orderErrors.ruleBroken.single
										: orderErrors.ruleBroken.multiple}
									<StyledListOfRuleErrors
										single={
											validationResult?.infoArray
												?.length < 2
										}>
										{validationResult?.infoArray.map(
											(info, i) => (
												<li key={i}>{info}</li>
											)
										)}
									</StyledListOfRuleErrors>
								</RuleErrorWrapper>
							);
							setAddressSearchDone(false);

							return;
						}
						if (meters?.length === 1) {
							const meter = meters[0];

							updateOrder({
								maalere: [
									{
										maalernummer: meter.maalernummer,
										maalepunktId: meter.maalepunktId,
									},
								],
								maalerAdresse:
									meter.adresse || searchResult.maalerAdresse,
								isManual: false,
							});
							trackStepCompleted(steps[3]);
							updateSaleStatus(salgsStatuser.meterFound);
							navigatePage(stromPages.addStartdate.url);
						} else {
							updateSearchResult({
								maalere: meters.map(m => {
									return {
										maalernummer: m.maalernummer,
										maalepunktId: m.maalepunktId,
									};
								}),
								maalerAdresse:
									meters[0].adresse ||
									searchResult.maalerAdresse,
							});
							navigatePage(stromPages.pickMeter.url);
						}
					}
				}}>
				<SearchAddressField
					inputRef={addressInputRef}
					pickedAddress={pickedAddress}
					setPickedAddress={setPickedAddress}
					error={formError}
					setError={setFormError}
					required={true}
					searchIsActive={searchIsActive}
					setSearchIsActive={setSearchIsActive}
				/>
				<Button
					type="submit"
					disabled={
						isLoading || isValidating || formError?.length > 0
					}>
					{addressSearchDone &&
					!isValidating &&
					formError?.length === 0 ? (
						<FontAwesomeIcon
							style={{ fontSize: '20px' }}
							icon={faCircleCheck}
						/>
					) : isLoading || isValidating ? (
						'Registrerer adresse...'
					) : (
						'Registrer adresse'
					)}
				</Button>
			</Form>
		</Layout>
	);
}
